(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("sip.js"), require("getstats"));
	else if(typeof define === 'function' && define.amd)
		define(["sip.js", "getstats"], factory);
	else if(typeof exports === 'object')
		exports["WebPhone"] = factory(require("sip.js"), require("getstats"));
	else
		root["RingCentral"] = root["RingCentral"] || {}, root["RingCentral"]["WebPhone"] = factory(root["SIP"], root["getStats"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__9__) {
return 